<style lang="scss" scoped>
.order-search-form {
    background: #ffffff;
    padding: 10px 20px 20px 10px;

    .screen-company-btn {
        /*float: right;*/
        position: relative;
        height: 40px;

        .screen-operate-sort {
            position: absolute;
            bottom: 0;
            left: 10px;
            font-size: 12px;

            .el-button {
                padding: 8px 20px;
            }

            .i-top {
                position: absolute;
                right: 5px;
                top: 5px;
            }

            .i-bottom {
                position: absolute;
                right: 5px;
                bottom: 5px;
            }
        }

        .screen-operate-btn {
            position: absolute;
            bottom: 0;
            right: 0;

            .el-button {
                padding: 8px 20px;
            }
        }
    }
}
</style>
<template>

    <div>
        <div class="order-search-form">
            <el-form size="small" inline>
                <el-row>
                    <el-col>
                        <el-form-item>
                            <el-radio-group v-model="searchForm.orderStatus">
                                <el-radio-button :label="-1">
                                    全部
                                </el-radio-button>
                                <el-radio-button :label="os.value" v-for="os in getOrderStatusList" :key="os.value">
                                    {{ os.label }}
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item>
                            <el-autocomplete
                                v-model="searchForm.createOrginfoName" style="width: 220px"
                                :fetch-suggestions="querySearchAsync"
                                placeholder="下单机构"
                                @select="selectAgency">
                                <template slot-scope="{ item }">
                                    <div class="list-pop-bot">
                                        <!--<span>{{'[' + item.orgId + ']'}}</span>-->
                                        <span>{{ item.merchantName }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker style="width: 240px" v-model="searchForm.admissionTime"
                                            type="daterange" value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="入园起始日期"
                                            end-placeholder="入园截止日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker style="width: 240px" v-model="searchForm.orderTime"
                                            type="daterange" value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="下单起始日期"
                                            end-placeholder="下单截止日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="导游姓名" style="width: 120px"
                                      v-model="searchForm.guiderName"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="手机号" style="width: 120px" maxlength="11"
                                      v-model="searchForm.guiderMobile"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="团号" style="width: 120px"
                                      v-model="searchForm.groupNo"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="订单ID" style="width: 120px"
                                      v-model="searchForm.ticketOrderNo"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-select style="width: 120px" v-model="searchForm.payStatus" placeholder="支付订单">
                                <el-option
                                    label="已支付"
                                    :value="1">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>


            </el-form>
            <el-row :gutter="20" class="screen-company-btn">
                <el-col :span="12">
                    <div class="screen-operate-btn">
                        <el-button
                            @click="orderExport(4)"
                            class="btn-border-319">
                            收支明细导出
                        </el-button>
                        <el-button
                            @click="orderExport(0)"
                            class="btn-border-319">
                            TDS订单财务导出
                        </el-button>
                        <!--                        <el-button-->
                        <!--                            @click="orderExport(1)"-->
                        <!--                            class="btn-border-319">-->
                        <!--                            导游订单财务导出-->
                        <!--                        </el-button>-->
                        <el-button
                            @click="orderExport(2)"
                            class="btn-border-319">
                            日订单导出
                        </el-button>
                        <!--                        <el-button-->
                        <!--                            @click="orderExport(3)"-->
                        <!--                            class="btn-border-319">-->
                        <!--                            未归还讲解器列表导出-->
                        <!--                        </el-button>-->
                        <el-button
                            @click="resetBtn()"
                            class="btn-border-319
                            reset-but">
                            重置
                        </el-button>
                        <el-button
                            type="primary"
                            @click="serach()">
                            搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import {customerServiceInterventionOrderStatus} from "@/data/creditRating";

export default {
    //定义模版数据
    data() {
        return {
            searchForm: {
                orderStatus: -1,
                ticketOrderNo: undefined
            },
            orderStatusList: [
                {value: 11, label: '待付款'},
                {value: 31, label: '待使用'},
                {value: 40, label: '进行中'},
                {value: 41, label: '已使用'},
                {value: 72, label: '已取消'},
                {value: 73, label: '出票失败'},
                {value: 51, label: '已过期'},
                ...customerServiceInterventionOrderStatus
            ],
        }
    },
    computed: {
        getOrderStatusList() {
            return this.orderStatusList.filter(({value}) => value !== 99)
        }
    },
    //主件被加载完成
    mounted() {
        if (this.$route.query.orderId)
            this.searchForm.ticketOrderNo = this.$route.query.orderId;
        this.serach();
    },
    //定义事件方法
    methods: {
        serach() {
            let form = JSON.parse(JSON.stringify(this.searchForm));
            this.$emit('searchOrder', form)
        },
        resetBtn() {
            this.searchForm = {
                orderStatus: -1,
                ticketOrderNo: undefined
            }
            this.$emit('searchOrder', this.searchForm)
        },
        async orderExport(type) {
            this.loadingShow();
            let form = JSON.parse(JSON.stringify(this.searchForm));
            if (form.admissionTime && form.admissionTime.length === 2) {
                form.startUseTime = form.admissionTime[0];
                form.endUseTime = form.admissionTime[1];
                delete form.admissionTime;
            }
            if (form.orderTime && form.orderTime.length === 2) {
                form.startCreateOrderTime = +new Date(form.orderTime[0]);
                form.createOrderTimeStart = +new Date(form.orderTime[0]);
                form.endCreateOrderTime = +new Date(form.orderTime[1] + " 23:59:59");
                delete form.orderTime;
            }

            form.resourceUseCar = this.type === 'trip' ? '0' : '1';
            form.createBy = type;  // 0tds   1导游端

            delete form.orderStatus;
            delete form.cancelStatus;
            delete form.confirmOrder;
            delete form.distributionMethod;

            let url = '', name = '';

            switch (type) {
                case 0:
                    name = 'TDS订单财务导出表';
                    url = '/galaxyZslTicketOrderApi/exportListTicketOrderFinance';
                    break;
                case 1:
                    name = '导游订单财务导出表';
                    url = '/galaxyOrder/financeExportOrder';
                    break;
                case 2:
                    name = '日订单导出表';
                    url = '/galaxyZslTicketOrderApi/exportTicketOrderResource';
                    break;
                case 3:
                    name = '未归还讲解器导出表';
                    url = '/galaxyZslTicketOrderApi/listTicketOrderAudioDetail';
                    break;
                case 4:
                    name = '收支明细导出';
                    url = '/galaxyZslTicketOrderApi/exportTicketOrderInfoList';
                    break;
            }
            try {
                let res = await this.http(url, form, 'POST', true, 'blob');
                console.log(res);
                let d = new Date().getTime();
                let a = document.createElement('a');
                a.download = `${name}${d}.xls`;
                a.href = window.URL.createObjectURL(res);
                a.click();
                this.loadingHide();
            } catch (e) {
                this.loadingHide();
                this.messageError('下载失败')
            }
        },
        async querySearchAsync(query, cb) {
            if (query && query.length > 1) {
                this.querySearch(query, cb, 1)
            }
        },
        async querySearch(query, cb, index) {
            let url = '';
            let form = {};
            let postType = 'GET';
            switch (index) {
                case 1:
                    url = '/galaxyMerchant/merchantInfo';
                    form = {
                        merchantName: query
                    }
                    break;
            }
            let res = await this.http(url, form, postType);
            if (res.success && res.data) {
                // let mechanismList = res.data;
                let mechanismList = query ? res.data.filter(this.createFilter(query, index)) : res.data;
                cb(mechanismList);
            } else {
                this.messageError('系统出错，请稍后!');
            }
        },
        createFilter(itemStr, index) {
            return (restaurant) => {
                let result = false;
                switch (index) {
                    case 1:
                        result = (restaurant.merchantName.indexOf(itemStr) >= 0);
                        break;
                }
                return result;
            };
        },
        async selectAgency(val) {
            this.searchForm.createOrginfoName = val.merchantName;
            this.searchForm.createOrderOrganId = val.id;
        },
    },
    //监听模版变量
    watch: {}

}
</script>
