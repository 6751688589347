/**
* Created by hansxing on 2019/4/10.
*/
<style lang="scss" scoped>
.guide-list {
    .guide-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }
    }

    .guide-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }
}

.guide-list /deep/ {
    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }

        .el-table__fixed-right-patch {
            width: 10px !important;
        }
    }

}
</style>
<style lang="scss">
.upper-lower-lines-dialog {
    top: 50%;
    margin: auto;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        text-align: center;
        font-size: 16px;
    }

    .el-dialog__footer {
        padding: 0 20px 20px 20px;
        text-align: center;
    }
}

.guide-list-table {
    .operation-btn {
        .el-button {
            padding: 0;
            margin: 5px;
            margin-left: 0;
            float: left;
        }
    }
}

.zsl-guide-list {
    .common-list-search .list-search-before {
        .el-form-item.el-form-item--small {
            width: auto;
            margin-bottom: 0;
            /*min-width: 320px;*/
            &.list-state {
                width: 165px;

                .el-form-item__label {
                    padding: 0;
                }

                .el-form-item__content {
                    width: calc(100% - 43px);
                }
            }
        }

        .el-form-item__content {
            /*width: auto;*/
        }
    }
}
</style>
<template>
    <div class="zsl-guide-list">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="guide-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="form" size="small">
                            <el-form-item style="width: 150px">
                                <el-input v-model="form.realName" placeholder="导游姓名"></el-input>
                            </el-form-item>
                            <el-form-item style="width: 120px">
                                <el-input v-model="form.bindingMobile" placeholder="绑定手机号"></el-input>
                            </el-form-item>
                            <el-form-item style="width: 120px">
                                <el-input v-model="form.userMobile" placeholder="注册手机号"></el-input>
                            </el-form-item>
                            <el-form-item style="width: 150px">
                                <el-input v-model="form.touristCertificate" placeholder="电子导游证号"></el-input>
                            </el-form-item>
                            <el-form-item label="状态：" label-width="43px" class="list-state">
                                <el-select v-model="form.isAvailable" placeholder="是否可用">
                                    <el-option label="全部" :value="null"></el-option>
                                    <el-option label="不可用" :value="0"></el-option>
                                    <el-option label="可用" :value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="handleReset()">重置</el-button>
                        <el-button type="primary" @click="handleSearch()">搜索</el-button>
                    </div>
                </com-list-search>
                <div class="guide-list-table el-table_border_none">
                    <el-table
                        type="index"
                        class="table-info"
                        border
                        :data="guideList"
                        v-loading="tableLoading"
                        style="width: 100%"
                    >
                        <el-table-column
                            type="index"
                            label="序号"
                            width="50">
                        </el-table-column>
                        <el-table-column prop="realName" label="导游姓名" width="110"></el-table-column>
                        <el-table-column prop="touristCertificate" width="120" label="电子导游证号"></el-table-column>
                        <el-table-column prop="bindingMobile" width="120" label="绑定手机号"></el-table-column>
                        <el-table-column prop="userMobile" width="120" label="注册手机号"></el-table-column>
                        <el-table-column label="证件号" min-width="120">
                            <template slot-scope="scope">
                                <p>{{ scope.row.certificateType | certificateDesc }}</p>
                                <p>{{ scope.row.idCardNumber }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.isAvailable==0">不可用</p>
                                <p v-if="scope.row.isAvailable==1">可用</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150">
                            <template slot-scope="scope">
                                <div class="operation-btn clear">
                                    <el-button
                                        style="color:#FF3B30"
                                        @click="handleEnable(scope.$index)"
                                        type="text"
                                        v-if="scope.row.isAvailable == 1"
                                    >停用
                                    </el-button>
                                    <el-button
                                        @click="handleEnable(scope.$index, true)"
                                        type="text"
                                        v-if="scope.row.isAvailable == 0"
                                    >启用
                                    </el-button>
                                    <el-button type="text" @click="handleSee(scope.row)">查看</el-button>

                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="handleSearch"
                        :current-page.sync="pageData.currentPage"
                        :page-size="pageData.pageSize"
                        layout="total, prev, pager, next"
                        :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
import comListSearch from "../../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import {certificateList} from "@/data/index";
import {findItem} from '@/common/js/common'

export default {
    //定义模版数据
    name: "guideList",
    data() {
        return {
            form: {
                isAvailable: null
            },
            guideList: [],
            tableLoading: false,
            certificateList,
            pageData: {
                pageSize: 10, // 每页显示条目个数
                totalPage: 1, // 总条目个数
                currentPage: 1 // 当前页数
            },
        };
    },
    components: {
        SectionContent,
        comListSearch,
    },
    filters: {
        certificateDesc(value) {
            return findItem(certificateList, value)
        }
    },
    //主件被加载完成
    mounted: function () {
        this.handleSearch(1);
    },
    //定义事件方法
    methods: {
        handleReset() {
            this.form = {
                realName: '', // 司机信息
                bindingMobile: '',
                userMobile: '',
                touristCertificate: '',
                isAvailable: null,
            };
            this.handleSearch(1);
        },
        async handleSearch(page) {
            this.tableLoading = true;
            let {
                    realName,
                    bindingMobile,
                    userMobile,
                    touristCertificate,
                    isAvailable,
                } = this.form,
                form = {
                    realName,
                    bindingMobile,
                    userMobile,
                    touristCertificate,
                    isAvailable,
                    pageIndex: page,
                    pageSize: this.pageData.pageSize,
                };
            let res = await this.http("/galaxyGuiderApi/listGuiderBackendInfo", form, "POST");
            this.tableLoading = false;
            if (res.success && res.data) {
                this.guideList = res.data.data;
                this.pageData.totalPage = res.data.total;
            }
        },
        handleSee(data) {
            this.$router.push(`/zslGuideTourList/${data.id}`);
        },
        async handleEnable(index, bool) {
            let form = {
                isAvailable: bool ? 1 : 0,
                id: this.guideList[index].id
            };
            let res = await this.http("/galaxyGuiderApi/availableGuiderOrNot", form, "POST");
            if (res.success && res.data) {
                this.$message.success("操作成功");
                let data = JSON.parse(JSON.stringify(this.guideList[index]));
                data.isAvailable = bool ? 1 : 0;
                this.$set(this.guideList, index, data);
            }
        },
    },
};
</script>

