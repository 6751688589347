<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont credit-points-list">
            <com-list-search>
                <div slot="list-search-before">
                    <el-form :inline="true" size="small">
                        <el-form-item label="旅行社名称">
                            <el-autocomplete placeholder="旅行社名称" clearable v-model="form.companyName"
                                             :fetch-suggestions="querySearch"></el-autocomplete>
                        </el-form-item>
                        <el-form-item label="信用等级">
                            <el-radio-group v-model="form.creditGrade">
                                <el-radio-button :label="-1">全部</el-radio-button>
                                <el-radio-button :label="vm.value" v-for="vm in creditRatingOptions"
                                                 :key="vm.value">
                                    {{ vm.label }}
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="账号状态">
                            <ApiSelect :options="getOnlineStateList" v-model="form.onlineStatus" clearable></ApiSelect>
                        </el-form-item>
                        <el-form-item label="排序规则">
                            <ApiSelect :options="sortRules" clearable v-model="form.sortField"></ApiSelect>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="list-search-after">
                    <el-button type="primary" @click="search()" :loading="loading">搜索</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <el-button type="primary" plain @click="search()" :loading="loading">导出</el-button>
                </div>
            </com-list-search>
            <div class="user-list-table el-table_border_none" style="margin-top: 10px">
                <el-table class="table-info" border :data="tabData.data" style="width: 100%">
                    <el-table-column width="80" label="序号" type="index">
                        <template slot-scope="scope">
                            <span
                                :class="scope.row.creditGrade | formatDesc(creditRatingOptions,'value','color')">
                                {{ scope.$index + 1 }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" label="机构ID" prop="orgId"></el-table-column>
                    <el-table-column label="旅行社名称" prop="companyName"></el-table-column>
                    <el-table-column label="注册地" prop="companyCityName"></el-table-column>
                    <el-table-column label="管理员" width="160" prop="businessContractName">
                        <template slot-scope="scope">
                            <p>{{ scope.row.businessContractName }}</p>
                            <p>{{ scope.row.businessContractPhone }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="当前等级" width="100" prop="creditGrade">
                        <template slot-scope="scope">
                            <span :class="scope.row.creditGrade | formatDesc(creditRatingOptions,'value','color')">
                            {{ scope.row.creditGrade | formatDesc(creditRatingOptions) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="当前积分" width="120" prop="creditScore">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="handlePointsDetailsDialogShow(scope.row)">
                                {{
                                    scope.row.creditScore
                                }}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column width="100">
                        <template #header>
                            <span>
                                近30天
                                <br/>
                                未履约次数
                            </span>
                        </template>
                        <template slot-scope="scope">
                            <el-link type="primary" @click="handleNonPerformanceRecordsDialogShow(scope.row)">
                                {{ scope.row.punishCount || 0 }}次
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column width="120">
                        <template #header>
                            <span>
                                未履约
                                <br/>
                                下线次数
                            </span>
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.punishOutCount || 0 }}次
                        </template>
                    </el-table-column>
                    <el-table-column label="当前状态" width="220">
                        <template slot-scope="scope">
                            <span v-if="scope.row.onlineStatus==='8'">
                                {{ `第${scope.row.punishOutCount}次未履约下线` }}
                            </span>
                            <span v-else>{{ scope.row.onlineStatus | formatDesc(onlineStateList) }}</span>
                            <p class="color-error font_s12"
                               v-if="scope.row.onlineTime&&!['1','0'].includes(scope.row.onlineStatus)">
                                {{ scope.row.onlineTime | countdown }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="180">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="handlePointsEntryShow(scope.row)">积分录入</el-link>
                            <el-link class="ml10" type="warning" @click="handleResetRecord(scope.row, scope.$index)">
                                重置
                            </el-link>
                            <el-link class="ml10" type="danger"
                                     :loading="loading"
                                     v-if="scope.row.onlineStatus === '1'"
                                     @click="handleOffline(scope.row, scope.$index)">
                                下线
                            </el-link>
                            <el-link v-else class="ml10" type="primary"
                                     :loading="loading"
                                     @click="handleSubmitGoLive(scope.row, scope.$index)">
                                上线
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-footer style="padding: 0;margin-left: -20px;margin-right: -20px">
            <div class="table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="handelPageChange"
                        :current-page.sync="form.pageIndex"
                        :page-size="form.pageSize"
                        layout="total, prev, pager, next"
                        :total="tabData.total">
                    </el-pagination>
                </div>
            </div>
        </el-footer>
        <el-dialog :visible.sync="offlineDialogShow" title="下线机构">
            <el-form :model="offlineForm" ref="offlineFormRef">
                <el-form-item>久柏易游国际旅行社</el-form-item>
                <el-form-item label="下线时长">
                    <div style="display: flex">
                        <el-radio-group v-model="offlineForm.onlineStatus">
                            <el-radio-button v-for="vm in onlineStateList.slice(1,3)" :label="vm.value"
                                             :key="vm.value">
                                {{ vm.label }}
                            </el-radio-button>
                        </el-radio-group>
                        <!--                        <el-form-item prop="offlineDurationNum"-->
                        <!--                                      :rules="{required:true,message:'请输入自定义天数'}"-->
                        <!--                                      v-if="offlineForm.offlineDuration==='自定义天数'">-->
                        <!--                            <el-input-number class="ml10" v-model="offlineForm.offlineDurationNum"-->
                        <!--                                             :min="1"-->
                        <!--                                             :max="100"-->
                        <!--                                             label="自定义天数"></el-input-number>-->
                        <!--                        </el-form-item>-->
                    </div>
                </el-form-item>
                <el-form-item align="right">
                    <el-button type="primary" plain @click="offlineDialogShow=false">取消</el-button>
                    <el-button type="primary" class="ml10" @click="handleSubmitOffline">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="pointsEntryShow" title="积分录入">
            <el-form :model="pointsEntryForm" ref="pointsEntryFormRef" label-width="120px">
                <el-form-item label="旅行社名称" v-if="currentRecord">
                    <!--                    <ApiSelect placeholder="旅行社名称"-->
                    <!--                               style="width: 100%"-->
                    <!--                               :api="getOrgInfoApiPageListApi"-->
                    <!--                               :replaceField="{label: 'companyName',value: 'id',key: 'id'}"-->
                    <!--                               :params="{pageIndex:1,pageSize:2000}"></ApiSelect>-->
                    {{ currentRecord.companyName }}
                </el-form-item>
                <el-form-item label="维度">
                    团队管理
                </el-form-item>
                <el-form-item label="项目" prop="name" :rules="[{required:true,message:'请选择项目'}]">
                    <el-select style="width: 100%"
                               filterable
                               clearable
                               class="points-entry-form_name"
                               v-model="pointsEntryForm.name" placeholder="请选择积分项"
                               v-loading="otherRulesLoading"
                               @change="handleOtherRulesChange">
                        <span slot="prefix" v-if="pointsEntryForm.name"
                              :class="pointsEntryForm.score >= 0?'color-success':'color-danger'">
                             {{ (pointsEntryForm.score >= 0 ? '+' : '') + pointsEntryForm.score }}
                        </span>
                        <el-option
                            v-for="item in otherRules"
                            :key="item.name"
                            :label="item.name"
                            :value="item.name">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right;  font-size: 13px"
                                  :class="item.fraction >= 0?'color-success':'color-danger'">
                                {{ (item.fraction >= 0 ? '+' : '') + item.fraction }}
                            </span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="具体原因" prop="info" :rules="[{required:true,message:'请输入具体原因'}]">
                    <el-input type="textarea" clearable placeholder="请填写具体原因" :maxlength="120" show-word-limit
                              v-model="pointsEntryForm.info"></el-input>
                </el-form-item>
                <el-form-item align="right">
                    <el-button type="primary" plain @click="pointsEntryShow=false" v-if="!loading">
                        取消
                    </el-button>
                    <el-button type="primary" class="ml10" @click="handleSubmitPointsEntry" :loading="loading">
                        确定
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <NonPerformanceRecordsDialog ref="nonPerformanceRecordsDialogRef"></NonPerformanceRecordsDialog>
        <PointsDetailsDialog ref="pointsDetailsDialogRef"></PointsDetailsDialog>
    </el-main>
</template>

<script>
import {defineComponent} from 'vue'
import confirmationsDialog from "@/www/pages/zslManage/bill/list/confirmations-dialog.vue";
import EditDialog from "@/www/pages/zslManage/bill/list/edit-dialog.vue";
import comListSearch from "@/www/components/comListSearch/index.vue";
import lookImages from "@/components/look-images/index.vue";
import SettlementForm from "@/www/pages/zslManage/bill/list/settlement-form.vue";
import QuantityDetailsDialog from "@/www/pages/zslManage/bill/list/quantity-details-dialog.vue";
import {creditRatingOptions, creditStatusOptions} from '@/data/creditRating'
import NonPerformanceRecordsDialog from "@/www/pages/zslManage/creditAndPoints/NonPerformanceRecordsDialog.vue";
import PointsDetailsDialog from "@/www/pages/zslManage/creditAndPoints/PointsDetailsDialog.vue";
import {
    getCreditListApi,
    getOrgInfoApiPageListApi,
    resettingApi,
    selectCreditRuleApi,
    updateCreditApi, updateZslOrgInfoOnlineApi
} from "@/www/urls/credit";
import {onlineStateList} from '../agencyList/data/index'
import dayjs from "dayjs";

export default defineComponent({
    name: "creditPoints",
    components: {
        QuantityDetailsDialog,
        SettlementForm,
        lookImages,
        comListSearch,
        EditDialog,
        confirmationsDialog,
        NonPerformanceRecordsDialog,
        PointsDetailsDialog
    },
    data() {
        return {
            loading: false,
            onlineStateList,
            creditRatingOptions,
            creditStatusOptions,
            offlineDialogShow: false,
            offlineDurationOptions: ['1天', '7天', '14天', '自定义天数'],
            offlineForm: {
                onlineStatus: '2',
                // offlineDuration: '1天',
                // offlineDurationNum: 1
            },
            pointsEntryShow: false,
            sortRules: [
                {label: '当前积分升序', value: 'credit_score-asc'},
                {label: '当前积分降序', value: 'credit_score-desc'},
                {label: '未履约次数升序', value: 'punish_count-asc'},
                {label: '未履约次数降序', value: 'punish_count-desc'},
                {label: '下线次数升序', value: 'punish_out_count-asc'},
                {label: '下线次数降序', value: 'punish_out_count-desc'}
            ],
            form: {
                pageSize: 10,
                pageIndex: 1,
                creditGrade: -1,
                onlineStatus: undefined,
                companyName: undefined,
                sortField: "punish_count-desc"
            },
            pointsEntryForm: {
                creditId: undefined,
                name: undefined,
                info: undefined,
                score: undefined
            },
            tabData: {
                total: 0,
                data: []
            },
            currentRecord: undefined,
            otherRules: [],
            otherRulesLoading: false
        }
    },
    computed: {
        getOnlineStateList() {
            return [...onlineStateList.slice(0, 3), {
                label: '系统自动下线',
                value: 99
            }, ...onlineStateList.slice(3)]
        }
    },
    filters: {
        countdown(time) {
            if (!time)
                return '';
            let total = dayjs(time).diff(dayjs(), 'ms');
            if (total <= 0) {
                return ''
            }
            const d = Math.floor(total / (24 * 60 * 60 * 1000));
            total = total % (24 * 60 * 60 * 1000);
            const h = Math.floor(total / (60 * 60 * 1000));
            total = total % (60 * 60 * 1000);
            const m = Math.floor(total / (60 * 1000));
            return `${d}天${h}小时${m}分钟后上线`
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        async querySearch(queryString, cb) {
            try {
                console.log(queryString);
                if (!queryString)
                    return cb([])
                const ret = await getOrgInfoApiPageListApi({
                    pageIndex: 1,
                    pageSize: 100,
                    companyName: queryString
                })
                if (ret.success) {
                    cb(ret.data.data.map(({companyName}) => ({
                        label: companyName,
                        value: companyName
                    })))
                } else cb([])
            } catch (err) {
                console.log(err);
                return cb([])
            }
        },
        getOrgInfoApiPageListApi,
        handlePointsEntryShow(record) {
            this.getOtherRules();
            this.pointsEntryShow = true
            this.pointsEntryForm.name = undefined;
            this.pointsEntryForm.info = undefined;
            this.$nextTick(() => {
                this.$refs['pointsEntryFormRef'].clearValidate()
            })
            this.currentRecord = record;
        },
        async search() {
            try {
                this.loading = true;
                const f = {...this.form};
                for (let key in f) {
                    if (f[key] === -1 || !f[key])
                        f[key] = undefined
                }
                if (f.onlineStatus === 99) {
                    f.onlineStatusList = onlineStateList.slice(3).map(({value}) => value);
                    f.onlineStatus = undefined;
                }
                if (f.sortField) {
                    const sort = f.sortField.split('-')
                    f.sortField = sort[0];
                    f.sortOrder = sort[1];
                }
                const ret = await getCreditListApi(f)
                if (ret.success) {
                    this.tabData = ret.data
                    clearInterval(this.timer)
                    this.timer = setInterval(() => {
                        this.tabData = JSON.parse(JSON.stringify(ret.data));
                    }, 30000)
                } else this.$message.error(ret.errors[0].message)
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
        handelPageChange(page) {
            this.form.pageIndex = page;
            this.search();
        },
        handleOffline(record) {
            // this.offlineForm.offlineDuration = '1天'
            this.offlineForm.onlineStatus = '2';
            this.offlineDialogShow = true;
            this.offlineForm.id = record.id
        },
        async handleResetRecord(record) {
            try {
                this.loading = true
                await this.$confirm(record.companyName, '确认重置机构')
                const ret = await resettingApi({
                    id: record.creditId
                })
                if (ret.success) {
                    this.$message.success('重置成功!')
                    this.search();
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false;
            }
        },
        async handleSubmitOffline() {
            try {
                this.loading = true
                await this.$refs['offlineFormRef'].validate()
                await this.$confirm(`确定操作下线？`, '提示')
                const ret = await updateZslOrgInfoOnlineApi(this.offlineForm)
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.search()
                    this.offlineDialogShow = false;
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleSubmitGoLive(record) {
            try {
                this.loading = true
                await this.$confirm(`确定操作上线？`, '提示')
                const ret = await updateZslOrgInfoOnlineApi({
                    id: record.id,
                    onlineStatus: '1'
                })
                if (ret.success) {
                    this.$message.success('操作成功！')
                    this.search()
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleSubmitPointsEntry() {
            try {
                this.loading = true;
                await this.$refs['pointsEntryFormRef'].validate()
                await this.$confirm(`确认 ${this.currentRecord.companyName} ${this.pointsEntryForm.name}${this.pointsEntryForm.score > 0 ? '+' : ''}${this.pointsEntryForm.score}分？`, '提示')
                const ret = await updateCreditApi({
                    ...this.pointsEntryForm,
                    creditId: this.currentRecord.creditId
                })
                if (ret.success) {
                    this.$message.success('积分更新成功')
                    this.search();
                    this.pointsEntryShow = false;
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false;
            }
        },
        handleReset() {
            this.form.pageIndex = 1;
            this.form.companyName = undefined;
            this.form.creditGrade = -1;
            this.form.onlineStatus = undefined;
            this.form.sortField = undefined;
            this.search()
        },
        async getOtherRules() {
            try {
                this.otherRulesLoading = true
                const ret = await selectCreditRuleApi()
                if (ret.success) {
                    if (ret.data && ret.data.otherRule)
                        this.otherRules = JSON.parse(ret.data.otherRule)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.otherRulesLoading = false;
            }
        },
        handleOtherRulesChange(val) {
            const item = this.otherRules.find(({name}) => name === val);
            this.pointsEntryForm.score = item.fraction;
            this.pointsEntryForm.info = item.info;
        },
        handlePointsDetailsDialogShow(record) {
            this.$refs['pointsDetailsDialogRef'].show(record)
        },
        handleNonPerformanceRecordsDialogShow(record) {
            this.$refs['nonPerformanceRecordsDialogRef'].show(record)
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
})
</script>
<style scoped lang="scss">
.credit-points-list::v-deep {
    .common-list-search .list-search-before .el-form-item.el-form-item--small,
    .common-list-search .list-search-before .el-form-item__content {
        width: auto;
    }

    .common-list-search .list-search-after {
        width: 300px;
    }


}

.points-entry-form_name::v-deep {
    .el-input__prefix {
        left: auto;
        right: 30px;
    }

    .el-input__inner {
        padding-right: 60px;
    }
}
</style>
