import http from "@/common/js/http";

/**
 * 修改规则
 * @param data
 */
export const createCreditRuleApi = async (data) => {
    return await http(`/credit/createCreditRule`, data, 'POST');
};

/**
 * 查询规则
 * @param data
 */
export const selectCreditRuleApi = async (data) => {
    return await http(`/credit/selectCreditRule`, data, 'POST');
};

/**
 * 更新积分规则
 * @param data
 */
export const updateCreditRuleApi = async (data) => {
    return await http(`/credit/updateCreditRule`, data, 'POST');
};

/**
 * 积分明细
 * @param data
 */
export const getCreditDetailListApi = async (data) => {
    return await http(`/credit/getCreditDetailList`, data, 'POST');
};

/**
 * 积分明细
 * @param data
 */
export const updateCreditApi = async (data) => {
    return await http(`/credit/updateCredit`, data, 'POST');
};

/**
 * 获取信誉积分列表
 * @param data
 */
export const getCreditListApi = async (data) => {
    return await http(`/credit/getCreditList`, data, 'POST');
};

/**
 * 旅行社列表
 * @param data
 */
export const getOrgInfoApiPageListApi = async (data) => {
    return await http(`/galaxyZslTicketOrderApi/orgInfoApiPageList`, data, 'POST');
};

/**
 * 重置
 * @param data
 */
export const resettingApi = async (data) => {
    return await http(`/credit/resetting`, data, 'POST');
};

/**
 * 更新状态
 * @param data
 */
export const updateZslOrgInfoOnlineApi = async (data) => {
    return await http(`/galaxyZslTicketOrderApi/updateZslOrgInfoOnline`, data, 'POST');
};

/**
 * 查询未履约记录
 * @param data
 */
export const getCreditPunishListApi = async (data) => {
    return await http(`/credit/getCreditPunishList`, data, 'POST');
};
