<style lang="scss" scoped>
.product-list {
    height: 100%;

    .product-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }
    }

    .product-list-table {
        background: #fff;
        margin-top: 10px;
        overflow: visible;
        overflow-x: scroll;
        overflow-y: hidden;

        .table-body, .table-head {

            ul {
                background: #fff;
                display: flex;
                min-height: 50px;

                li {
                    border-right: 1px solid #F0F2F5;
                    border-bottom: 1px solid #F0F2F5;
                    align-items: center;
                    display: flex;
                    font-size: 14px;
                    background: #fff;
                    padding: 5px;

                    &:last-child {
                        border-right: 0;
                    }

                    &.agency-list-center {
                        /*line-height: initial;*/
                        /*display: flex;*/
                        /*align-items: center*/
                    }

                    &.li-6 {
                        width: 60px;
                        min-width: 60px;
                    }
                }

                .agency-w-1 {
                    width: 40px;
                    min-width: 40px;
                }

                .agency-w-2 {
                    width: 65px;
                    min-width: 65px;
                }

                .agency-w-3 {
                    //flex: 1;
                    width: 300px;
                    min-width: 300px;
                    /*min-width: 288px;*/
                    /*min-width: 309px;*/
                    /*width: calc(100% - 1007px);*/
                }

                .agency-w-4 {
                    width: 100px;
                    min-width: 100px;
                }

                .agency-w-5 {
                    width: 90px;
                    min-width: 90px;
                }

                .agency-w-6 {
                    width: 30px;
                    //width: 65px;
                    //min-width: 65px;
                }

                .agency-w-7 {
                    width: 65px;
                    min-width: 65px;
                }

                .agency-w-8 {
                    width: 60px;
                    min-width: 60px;
                }

                .agency-w-9 {
                    width: 210px;
                    min-width: 210px;
                    flex-wrap: wrap;

                    .el-button {
                        padding: 0;
                    }
                }

                .agency-w-10 {
                    width: 80px;
                    min-width: 80px;
                }

                .agency-w-11 {
                    width: 150px;
                    min-width: 150px;
                }

                .agency-w-12 {
                    width: 150px;
                    min-width: 150px;
                }
            }

        }

        .table-body {


        }

        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }

    .agency-list-edit {
        border: 1px solid #F0F2F5;
        background: #F0F8FF;
        padding: 16px 20px;


        h3 {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        .el-form-item.el-form-item--small {
            margin-bottom: 0;
        }

        .el-form-item__label {
            padding-right: 0;
        }
    }

}

.dialog-look-picture {
    .el-dialog__header {
        background: #F2F2F2;
        padding: 15px 15px;

        .el-dialog__headerbtn {
            top: 20px;
            right: 30px;
        }
    }


    img {
        width: auto;
    }
}

.danger-color {
    color: #FF3B30;
}
</style>
<style lang="scss">
.common-list-search .list-search-before {
    .label-pub-w {
        &.el-form-item.el-form-item--small {
            width: auto;
            /*min-width: 320px;*/
        }

        .el-form-item__content {
            width: auto;
        }
    }
}

.agency-list-operator {
    .el-input__inner {
        border: 0;
    }
}

.determine-dialog-form {
    .el-form-item__content {
        width: calc(100% - 100px);

        .image-cont {
            margin-left: 0;
        }
    }

    .el-form-item__label {
        padding-right: 0;
    }
}

.dialog-look-picture {
    overflow: hidden;

    .dialog-look-picture-custom {
        margin-top: 10px !important;
        height: calc(100% - 20px);
        overflow: hidden;
    }

    .el-dialog__body {
        padding: 10px;
        text-align: center;
        height: calc(100% - 80px);
        overflow: hidden;
        overflow-y: scroll;
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="product-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form inline :model="form" size="small">
                            <el-form-item style="width: auto">
                                <el-autocomplete
                                    v-model="form.companyName" style="width: 300px"
                                    :fetch-suggestions="querySearchAsync"
                                    placeholder="下单机构"
                                    @select="selectAgency">
                                    <template slot-scope="{ item }">
                                        <div class="list-pop-bot">
                                            <!--<span>{{'[' + item.orgId + ']'}}</span>-->
                                            <span>{{ item.merchantName }}</span>
                                        </div>
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                            <el-form-item style="width: auto">
                                <!--                                v-if="form.companyProvince && form.companyCity"-->
                                <cascader-city
                                    show-type="1"
                                    provinceTitle="全国"
                                    cityTitle="全部城市"
                                    ref="cascaderCityForm"
                                    @update:provinceCity="handleProvinceCity">
                                </cascader-city>
                            </el-form-item>

                            <el-form-item>
                                <el-input style="width: 120px" v-model="form.businessContractName"
                                          placeholder="联系人姓名"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input style="width: 120px" :maxlength="11" v-model="form.businessContractPhone"
                                          placeholder="手机号"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-select style="width: 120px" v-model="form.onlineStatus" placeholder="状态">
                                    <el-option
                                        v-for="item in onlineStateList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select style="width: 120px" v-model="form.isCompleted" placeholder="审核状态">
                                    <el-option
                                        v-for="item in auditStateList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select style="width: 120px" v-model="form.signStatus" placeholder="签约信息">
                                    <el-option
                                        v-for="item in signStatusList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="resetClick()">重置</el-button>
                                <el-button type="primary" @click="handleSearch()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="addTravelInfo(1)">添加旅行社信息</el-button>
                    </div>
                </com-list-search>

                <div class="product-list-table">
                    <div class="table-head" style="color: #909399;">
                        <ul>
                            <li class="agency-w-1">序号</li>
                            <li class="agency-w-2">机构ID</li>
                            <li class="agency-w-3">旅行社名称</li>
                            <li class="agency-w-4">管理员</li>
                            <li class="agency-w-4">注册地</li>
                            <li class="agency-w-5">手机号</li>
                            <li class="li-6">营业执照</li>
                            <li class="agency-w-11">统一社会信用代码</li>
                            <li class="li-6">电子公章</li>
                            <li class="li-6">旅行社经营许可证</li>
                            <li class="li-6">授权书</li>
                            <li class="li-6">充值信息</li>
                            <li class="li-6">签约信息</li>
                            <li class="agency-w-8">状态</li>
                            <li class="agency-w-8">审核状态</li>
                            <li class="agency-w-9">操作</li>
                        </ul>
                    </div>

                    <div class="table-body" v-for="(item,index) in agencyList" :key="item.id">
                        <ul>
                            <li class="agency-w-1">{{
                                    (pageInfo.currentPage - 1) * pageInfo.pageSize + (index + 1)
                                }}
                            </li>
                            <li class="agency-w-2">{{ item.orgId }}</li>
                            <li class="agency-w-3 agency-list-center">{{ item.companyName }}</li>
                            <li class="agency-w-4 agency-list-center">{{ item.businessContractName }}</li>
                            <li class="agency-w-4 agency-list-center">
                                {{ item.companyProvinceName }}
                                <span
                                    v-if="item.companyCityName != item.companyProvinceName">{{
                                        item.companyCityName
                                    }}</span>
                            </li>
                            <li class="agency-w-5">{{ item.businessContractPhone }}</li>
                            <li class="li-6">
                                <look-big-images
                                    title="查看"
                                    :disabled="!item.businessLincensePhoto"
                                    id="businessLincensePhoto"
                                    :imagesUrl="item.businessLincensePhoto">
                                </look-big-images>
                            </li>
                            <li class="agency-w-11">
                                {{ item.businessLicenseNumber }}
                            </li>
                            <li class="li-6">
                                <look-big-images
                                    title="查看"
                                    :disabled="!item.officialSealPhoto"
                                    id="businessLincensePhoto"
                                    :imagesUrl="item.officialSealPhoto">
                                </look-big-images>
                            </li>
                            <li class="li-6">
                                <look-big-images
                                    title="查看"
                                    :disabled="!item.travelBusinessLicensePhoto"
                                    id="businessLincensePhoto"
                                    :imagesUrl="item.travelBusinessLicensePhoto">
                                </look-big-images>
                            </li>
                            <li class="li-6">
                                <look-big-images
                                    title="查看"
                                    :disabled="!item.powerAttorney"
                                    id="businessLincensePhoto"
                                    :imagesUrl="item.powerAttorney">
                                </look-big-images>

                            </li>
                            <li class="agency-w-8">
                                <span>{{ item.orgChargeInfoDto ? '已' : '未' }}启用</span>
                            </li>
                            <li class="agency-w-8">
                                <span v-if="item.signStatus === 0">未签约</span>
                                <span v-if="item.signStatus === 1">已签约</span>
                            </li>
                            <li class="agency-w-8">
                                <span>{{ item.onlineStatus | formatDesc(onlineStateList) }}</span>
                            </li>
                            <li class="agency-w-8">
                                <span>{{ item.isCompleted | formatDesc(auditStateList) }}</span>
                            </li>
                            <li class="agency-w-9 agency-list-center">
                                <el-button type="text" @click="handleTravelInfo(item, 3)">查看</el-button>
                                <el-button type="text" @click="handleTravelInfo(item, 2)">修改</el-button>
                                <el-popover
                                    v-if="item.onlineStatus == 1"
                                    placement="right"
                                    title=""
                                    width="200"
                                    trigger="click"
                                    v-model="item.OfflineShow"
                                    @show="popoverShow('OfflineShow', index)">
                                    <div>
                                        <p>永久下线</p>
                                        <p>机构：{{ item.companyName }}</p>
                                    </div>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text" @click="cancelClose(index)">取消</el-button>
                                        <el-button type="primary" size="mini"
                                                   @click="updateOrgInfoOnline(item, 0)">确定
                                        </el-button>
                                    </div>
                                    <el-button slot="reference"
                                               style="color:#FF3B30" type="text">
                                        <span>永久下线</span>
                                    </el-button>
                                </el-popover>
                                <el-popover
                                    v-if="item.onlineStatus != 1"
                                    placement="right"
                                    title=""
                                    width="200"
                                    trigger="click"
                                    v-model="item.goOnlineShow"
                                    @show="popoverShow('goOnlineShow', index)">
                                    <div>
                                        <p>上线</p>
                                        <p>机构：{{ item.companyName }}</p>
                                    </div>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text" @click="cancelClose(index)">取消</el-button>
                                        <el-button type="primary" size="mini"
                                                   @click="updateOrgInfoOnline(item, 1)">确定
                                        </el-button>
                                    </div>
                                    <el-button slot="reference"
                                               style="color:#FF3B30" type="text">
                                        <span>上线</span>
                                    </el-button>
                                </el-popover>
                                <el-popover
                                    v-if="item.onlineStatus == 1"
                                    placement="right"
                                    title=""
                                    width="200"
                                    trigger="click"
                                    v-model="item.Offline7Show"
                                    @show="popoverShow('Offline7Show', index)">
                                    <div>
                                        <p>下线七天</p>
                                        <p>机构：{{ item.companyName }}</p>
                                    </div>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text" @click="cancelClose(index)">取消</el-button>
                                        <el-button type="primary" size="mini" @click="updateOrgInfoOnline(item, 2)">确定
                                        </el-button>
                                    </div>
                                    <el-button slot="reference" style="color:#FF3B30" type="text">下线7天</el-button>
                                </el-popover>
                                <el-button
                                    v-if="item.isCompleted === 1"
                                    type="text"
                                    @click="handleTravelInfo(item, 4)">
                                    审核
                                </el-button>
                                <!--<el-button type="text" style="color:#FF3B30" @click="showChange(index, 1, item)">编辑管理员-->
                                <!--</el-button>-->
                                <el-button type="text" @click="showChange(index, 2, item)">编辑操作员</el-button>
                                <el-button type="text" @click="showChange(index, 3, item)">查看部门</el-button>
                            </li>
                        </ul>
                        <div class="agency-list-edit" v-if="item.isOrderChange&&item.showIndex==1">
                            <h3>编辑管理员</h3>
                            <el-form inline :model="item.adminForm" size="small">
                                <el-form-item label="管理员姓名：">
                                    <el-input v-model="item.adminForm.userName" placeholder="管理员姓名"></el-input>
                                </el-form-item>
                                <el-form-item label="管理员手机号：">
                                    <el-input v-model="item.adminForm.userMobile" maxlength="11"
                                              placeholder="管理员手机号"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" @click="cancelClose(index)">取消</el-button>
                                    <el-button type="primary" size="small"
                                               @click="updateZslOrgInfoConnect(item, index)">
                                        确定
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="agency-list-edit agency-list-operator" v-if="item.isOrderChange&&item.showIndex==2">
                            <h3>编辑操作员</h3>
                            <div style="position: relative;width: 600px">
                                <el-table
                                    :data="item.operatorForm"
                                    border
                                    style="width: 500px">
                                    <el-table-column
                                        fixed
                                        type="index"
                                        label="序号"
                                        width="50">
                                    </el-table-column>
                                    <el-table-column
                                        label="姓名"
                                        width="120">
                                        <template slot-scope="scope">
                                            <span v-if="!scope.row.isEdit">{{ scope.row.loginName }}</span>
                                            <el-input v-else v-model="scope.row.loginName"
                                                      placeholder="姓名"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="手机号">
                                        <template slot-scope="scope">
                                            <div>
                                                <span v-if="!scope.row.isEdit">{{ scope.row.mobile }}</span>
                                                <el-input v-else
                                                          v-model="scope.row.mobile"
                                                          maxlength="11"
                                                          placeholder="手机号">
                                                </el-input>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="状态"
                                        width="80">
                                        <template slot-scope="scope">
                                            <div>
                                                <span v-if="!scope.row.isEdit">
                                                    {{ operatorStatus[scope.row.usefulStatus] }}
                                                </span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        fixed="right"
                                        label="操作"
                                        width="120">
                                        <template slot-scope="scope">
                                            <el-button
                                                @click="handleStatusBtnClick(0,scope.row.loginuserManageId, index, item)"
                                                type="text"
                                                v-if="scope.row.usefulStatus == 1"
                                                class="danger-color"
                                                size="small">
                                                停用
                                            </el-button>
                                            <el-button
                                                @click="handleStatusBtnClick(1,scope.row.loginuserManageId, index, item)"
                                                type="text"
                                                v-if="scope.row.usefulStatus == 0"
                                                size="small">
                                                启用
                                            </el-button>
                                            <el-button @click="handleEditBtnClick(scope.row,scope,index, item)"
                                                       type="text"
                                                       size="small" v-if="!scope.row.loginuserManageId">
                                                保存
                                            </el-button>
                                            <!--{{scope.row.loginuserManageId}}-->
                                            <el-button @click="handleEditBtnClick(scope.row,scope,index, item)"
                                                       type="text"
                                                       size="small" v-else>
                                                {{ scope.row.isEdit ? '保存' : '修改' }}
                                            </el-button>
                                            <el-button
                                                @click="handleDeleteBrnClick(scope.row.loginuserManageId,index, item)"
                                                v-if="!scope.row.isEdit"
                                                type="text"
                                                class="danger-color"
                                                size="small">删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <el-button style="position: absolute;top: 0;right: 0" type="primary"
                                           @click="addWayAddress(index)">添加
                                </el-button>
                            </div>
                        </div>
                        <div class="agency-list-edit agency-list-operator" v-if="item.isOrderChange&&item.showIndex==3">
                            <h3>查看部门</h3>
                            <div>
                                <el-table
                                    :data="item.secondOrgList"
                                    border
                                    style="width: auto">
                                    <el-table-column
                                        type="index"
                                        label="序号"
                                        width="50">
                                    </el-table-column>
                                    <el-table-column
                                        prop="companyName"
                                        label="部门名称"
                                        width="330">
                                    </el-table-column>
                                    <el-table-column
                                        label="所在地"
                                        width="220">
                                        <template slot-scope="scope">
                                            <cascader-city
                                                disabled
                                                v-model="scope.row.companyAddress"></cascader-city>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="业务联系人 姓名/手机/邮箱">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.businessContractName }}</span>
                                            <span v-if="scope.row.businessContractPhone">{{
                                                    '/' + scope.row.businessContractPhone
                                                }}</span>
                                            <span v-if="scope.row.businessContractMail">{{
                                                    '/' + scope.row.businessContractMail
                                                }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="name"
                                        label="状态"
                                        width="120">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.onlineStatus | formatDesc(onlineStatusList) }}</span>
                                        </template>
                                    </el-table-column>

                                    <!--                                    <el-table-column-->
                                    <!--                                        fixed="right"-->
                                    <!--                                        label="操作"-->
                                    <!--                                        width="120">-->
                                    <!--                                        <template slot-scope="scope">-->
                                    <!--                                            <el-button-->
                                    <!--                                                @click="handleStatusBtnClick(0,scope.row.loginuserManageId, index, item)"-->
                                    <!--                                                type="text"-->
                                    <!--                                                v-if="scope.row.usefulStatus == 1"-->
                                    <!--                                                class="danger-color"-->
                                    <!--                                                size="small">-->
                                    <!--                                                停用-->
                                    <!--                                            </el-button>-->
                                    <!--                                            <el-button-->
                                    <!--                                                @click="handleStatusBtnClick(1,scope.row.loginuserManageId, index, item)"-->
                                    <!--                                                type="text"-->
                                    <!--                                                v-if="scope.row.usefulStatus == 0"-->
                                    <!--                                                size="small">-->
                                    <!--                                                启用-->
                                    <!--                                            </el-button>-->
                                    <!--                                            <el-button @click="handleEditBtnClick(scope.row,scope,index, item)"-->
                                    <!--                                                       type="text"-->
                                    <!--                                                       size="small" v-if="!scope.row.loginuserManageId">-->
                                    <!--                                                保存-->
                                    <!--                                            </el-button>-->
                                    <!--                                            &lt;!&ndash;{{scope.row.loginuserManageId}}&ndash;&gt;-->
                                    <!--                                            <el-button @click="handleEditBtnClick(scope.row,scope,index, item)"-->
                                    <!--                                                       type="text"-->
                                    <!--                                                       size="small" v-else>-->
                                    <!--                                                {{ scope.row.isEdit ? '保存' : '修改' }}-->
                                    <!--                                            </el-button>-->
                                    <!--                                            <el-button-->
                                    <!--                                                @click="handleDeleteBrnClick(scope.row.loginuserManageId,index, item)"-->
                                    <!--                                                v-if="!scope.row.isEdit"-->
                                    <!--                                                type="text"-->
                                    <!--                                                class="danger-color"-->
                                    <!--                                                size="small">删除-->
                                    <!--                                            </el-button>-->
                                    <!--                                        </template>-->
                                    <!--                                    </el-table-column>-->
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </section-content>
        </el-main>
        <!--查看大图  popup  begin-->
        <!--        <el-dialog class="dialog-look-picture" custom-class="dialog-look-picture-custom" title="查看" center-->
        <!--                   :visible.sync="lookPictureImg">-->
        <!--            <p v-if="lookPictureImgUrl">-->
        <!--                <see-pdf :src="lookPictureImgUrl" v-if="isFilePdf"></see-pdf>-->
        <!--                <img :src="lookPictureImgUrl" v-else>-->


        <!--            </p>-->
        <!--            <p style="text-align: center;padding-top: 20px" v-else>暂无照片</p>-->
        <!--        </el-dialog>-->
        <!--查看大图  popup end-->

        <!--        :show-close="false"-->
        <dialog-middle-section
            :title="dialogTitle + '旅行社信息'"
            :width="500"
            :show="determineShow"
            @onClose="handleCancel('determineForm')"
            is-footer>
            <div slot="dialog-section">
                <el-dialog
                    title="请输入拒绝通过的原因"
                    width="500px"
                    :close-on-click-modal="false"
                    :show-close="false"
                    custom-class="el-dialog-middle-center"
                    class="mod-driver-confirm"
                    :visible.sync="auditShow"
                    append-to-body>
                    <el-form :model="auditForm" :rules="auditRuleForm" ref="auditForm">
                        <el-form-item prop="refuseInfo">
                            <el-input
                                type="textarea"
                                :rows="5"
                                resize="none"
                                placeholder="请输入拒绝通过的原因"
                                v-model="auditForm.refuseInfo">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button size="small" @click="auditShow = false">取 消</el-button>
                        <el-button
                            :loading="refuseCompletedLoad"
                            size="small"
                            type="primary"
                            @click="handleRefuseInfo">确 定
                        </el-button>
                    </div>


                </el-dialog>


                <div class="check-demand-info">
                    <el-form inline :model="determineForm" :rules="determineRuleForm" ref="determineForm"
                             class="determine-dialog-form" size="small">
                        <el-form-item label="旅行社名称：" prop="companyName" label-width="100px" style="width: 100%">
                            <el-input placeholder="旅行社名称" :disabled="!handleSeeShow" maxlength="100"
                                      v-model="determineForm.companyName"></el-input>
                        </el-form-item>
                        <el-form-item label="注册所在地：" prop="companyRegistered" label-width="100px"
                                      style="width: 100%">
                            <cascader-city
                                :disabled="!handleSeeShow"
                                style="width: 100%"
                                v-model="determineForm.companyRegistered">
                            </cascader-city>
                        </el-form-item>
                        <el-form-item label="管理员姓名：" prop="businessContractName" label-width="100px"
                                      style="width: 100%">
                            <el-input style="width: 100%" :disabled="!handleSeeShow"
                                      v-model="determineForm.businessContractName"
                                      placeholder="管理员姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号：" prop="businessContractPhone" label-width="100px"
                                      style="width: 100%">
                            <el-input style="width: 100%" v-model="determineForm.businessContractPhone"
                                      placeholder="手机号" :disabled="!handleSeeShow" maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱：" prop="businessContractMail" label-width="100px" style="width: 100%">
                            <el-input style="width: 100%" v-model="determineForm.businessContractMail"
                                      placeholder="邮箱" :disabled="!handleSeeShow"></el-input>
                        </el-form-item>
                        <el-form-item label="签约信息：" label-width="100px" style="width: 100%; margin-bottom: 0">
                            <el-form-item style="width: 100%">
                                <p>
                                    <el-radio v-model="determineForm.signStatus" :disabled="!handleSeeShow" :label="0">
                                        未签约
                                    </el-radio>
                                </p>
                                <p>
                                    <el-radio v-model="determineForm.signStatus" :disabled="!handleSeeShow" :label="1">
                                        已签约
                                    </el-radio>
                                    <span class="ml10" v-if="determineForm.signStatus === 1">合同备份：</span>
                                </p>
                                <image-upload
                                    v-if="determineForm.signStatus === 1"
                                    @update:imageUploadCompleted="imageUploadBackupContractPhoto" :max="1"
                                    accept="image/*, application/pdf"
                                    height="80px"
                                    width="104px"
                                    :images="determineForm.backupContractPhoto"
                                    :isFilePdf="seeBackupContractPhoto"
                                    :disabled="!handleSeeShow">
                                </image-upload>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item label="有效期：" label-width="100px" v-if="determineForm.signStatus === 1"
                                      prop="backupContractDate">
                            <el-date-picker
                                v-model="determineForm.backupContractDate"
                                type="daterange"
                                :disabled="!handleSeeShow"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="充值信息：" label-width="100px" style="width: 100%; margin-bottom: 0">
                            <el-form-item style="width: 100%">
                                <div>充值账户{{ determineForm.orgChargeInfoDto ? '已' : '未' }}启用</div>
                                <ul>
                                    <li v-if="determineForm.orgChargeInfoDto && determineForm.orgChargeInfoDto.firstChargeAmount">
                                        首冲时间：{{
                                            determineForm.orgChargeInfoDto.firstChargeTime | dateCus('yyyy-MM-dd hh:mm:ss')
                                        }}
                                    </li>
                                    <li v-if="determineForm.orgChargeInfoDto && determineForm.orgChargeInfoDto.firstChargeAmount">
                                        首冲金额：¥{{ determineForm.orgChargeInfoDto.firstChargeAmount | currency }}
                                    </li>
                                    <li v-if="determineForm.orgChargeInfoDto">
                                        当前余额：¥{{ determineForm.orgChargeInfoDto.accountBalance | currency }}
                                    </li>
                                </ul>
                            </el-form-item>

                        </el-form-item>
                        <div style="display: flex">
                            <el-form-item label="营业执照：" prop="businessLincensePhoto" label-width="100px">
                                <div class="image-upload">
                                    <image-upload @update:imageUploadCompleted="imageUploadBusiness" :max="1"
                                                  accept="image/*, application/pdf"
                                                  height="80px" width="104px"
                                                  :images="determineForm.businessLincensePhoto"
                                                  :isFilePdf="seeBusiness"
                                                  :disabled="!handleSeeShow"></image-upload>
                                </div>
                            </el-form-item>
                            <el-form-item label="电子公章：" prop="officialSealPhoto" label-width="100px">
                                <div class="image-upload">
                                    <image-upload @update:imageUploadCompleted="imageUploadOfficial" :max="1"
                                                  accept="image/*, application/pdf"
                                                  height="80px" width="104px" :images="determineForm.officialSealPhoto"
                                                  :isFilePdf="seeOfficial"
                                                  :disabled="!handleSeeShow"></image-upload>
                                </div>
                            </el-form-item>
                        </div>
                        <div style="display: flex">

                            <el-form-item label="旅行社经营许可证：" prop="travelBusinessLicensePhoto"
                                          label-width="100px">
                                <div class="image-upload">
                                    <image-upload @update:imageUploadCompleted="imageUploadTravelBusiness" :max="1"
                                                  accept="image/*, application/pdf"
                                                  height="80px" width="104px"
                                                  :images="determineForm.travelBusinessLicensePhoto"
                                                  :isFilePdf="seeTravelBusiness"
                                                  :disabled="!handleSeeShow"></image-upload>
                                </div>
                            </el-form-item>
                            <el-form-item label="授权书：" prop="powerAttorney" label-width="100px">
                                <div class="image-upload">
                                    <image-upload @update:imageUploadCompleted="imageUploadPowerAttorney" :max="1"
                                                  accept="image/*, application/pdf"
                                                  height="80px" width="104px" :images="determineForm.powerAttorney"
                                                  :isFilePdf="seePowerAttorney"
                                                  :disabled="!handleSeeShow"></image-upload>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

            </div>
            <div slot="dialog-footer">
                <el-button
                    size="small"
                    v-if="operationBtnType === 1 || operationBtnType === 2"
                    @click="handleCancel('determineForm')">
                    取消
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    v-if="operationBtnType === 1"
                    @click="addTravelAgency('determineForm', 1)">
                    确定
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    v-if="operationBtnType === 2"
                    @click="addTravelAgency('determineForm', 2)">
                    确定
                </el-button>
                <el-button
                    type="primary"
                    size="small"
                    v-if="operationBtnType === 3"
                    @click="handleCancel('determineForm')">
                    确定
                </el-button>
                <div v-if="operationBtnType === 4">
                    <el-button
                        type="danger"
                        size="small"
                        @click="handleAudit(1)">
                        拒绝
                    </el-button>
                    <el-popover
                        placement="top"
                        width="160"
                        v-model="throughShow">
                        <p class="mt10 mb10">确定审核通过？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button
                                size="small"
                                @click="throughShow = false">
                                取消
                            </el-button>
                            <el-button
                                type="primary"
                                size="small"
                                :loading="passCompletedLoad"
                                @click="handlePassCompleted">
                                确定
                            </el-button>
                        </div>
                        <el-button
                            style="margin-left: 10px"
                            type="primary"
                            size="small"
                            slot="reference">
                            通过
                        </el-button>
                    </el-popover>
                </div>
            </div>
        </dialog-middle-section>
        <!--        <el-dialog-->
        <!--            :title="dialogTitle + '旅行社信息'"-->
        <!--            width="500px"-->
        <!--            :close-on-click-modal="false"-->
        <!--            custom-class="el-dialog-middle-center"-->
        <!--            class="mod-driver-confirm"-->
        <!--            @close="handleCancel('determineForm')"-->
        <!--            :visible.sync="determineShow">-->
        <!--            -->
        <!--            <div slot="footer" class="dialog-footer">-->
        <!--                -->
        <!--            </div>-->
        <!--        </el-dialog>-->
        <pagination-foot
            ref="paginationFoot"
            :total="pageInfo.total"
            @update:pageInfo="handlePageInfo">
        </pagination-foot>
    </div>
</template>

<script type="text/ecmascript-6">
import comListSearch from "../../../components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import paginationFoot from "@/components/pagination-foot/index";
import {
    isCellphone,
} from '@/common/js/Utils';
import ImageUpload from '@/components/imageUpload';
// import seePdf from 'vue-pdf'

import {onlineStateList, auditStateList, signStatusList} from './data/index'
import CascaderCity from '@/components/cascader-city/index'
import {passCompleted, refuseCompleted} from '@/www/urls/zslAgencyList'
import lookBigImages from '@/components/look-big-images/index'
import {isFilePdf} from '@/common/js/Utils'
import dialogMiddleSection from '@/components/dialog-middle-section/index'
import {combinationForm} from '@/common/js/common'
import dayjs from "dayjs";
// 二级分销部门状态 0:启用 1:停用   onlineStatus
const onlineStatusList = [
    {
        label: '停用',
        value: 0
    },
    {
        label: '启用',
        value: 1
    }
]
export default {
    //定义模版数据
    name: "zslAgencyList",

    data() {

        let validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请填写手机号'));
            } else if (!isCellphone(value)) {
                callback(new Error('手机号格式错误'));
            } else {
                callback();
            }
        };
        let isCompanyRegistered = (rule, value, callback) => {
            if (this.determineForm.companyProvince || this.determineForm.companyCity) callback();
            if (value && value.length > 0) callback();
            else callback(new Error('请选择省市'));
        };
        return {
            onlineStateList,
            auditStateList,
            onlineStatusList,
            signStatusList,
            dialogTitle: '',
            auditShow: false,  // 审核内层dialog
            auditForm: {
                refuseReason: ''  // 拒绝原因
            },  // 审核form
            throughShow: false,  // 审核通过popover
            form: {},
            agencyList: [],
            operatorStatus: ['已停用', '已启用'],
            pageInfo: {},
            operationBtnType: '',
            // lookPictureImg: false,  //查看大图
            // lookPictureImgUrl: '',  //大图url
            determineShow: false,
            // isFilePdf: false,  // 文件是否是pdf
            seeBusiness: false,
            seeOfficial: false,
            seeTravelBusiness: false,
            seePowerAttorney: false,
            seeBackupContractPhoto: false,
            determineForm: {
                businessLincensePhoto: [],
                officialSealPhoto: [],
                travelBusinessLicensePhoto: [],
                powerAttorney: [],
                companyRegistered: [],
                companyProvince: '',
                companyCity: '',
                signStatus: 0,
                orgChargeInfoDto: {},
                backupContractDate: []
            },
            handleSeeShow: true,
            refuseCompletedLoad: false, // 拒绝btn loading
            passCompletedLoad: false, // 通过btn loading
            determineRuleForm: {
                companyName: [{required: true, message: '请填写公司名称', trigger: 'blur'}],
                businessContractName: [{required: true, message: '请填写用户名称', trigger: 'blur'}],
                businessContractPhone: [{required: true, message: '请填写手机号', trigger: 'blur'}, {
                    validator: validatePhone,
                    trigger: 'blur'
                }],
                companyRegistered: [
                    {required: true, validator: isCompanyRegistered, trigger: 'change'},
                ],
                businessContractMail: [
                    {message: '请输入邮箱地址', trigger: 'blur'},
                    {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
                ],
                businessLincensePhoto: {required: true, message: '请选择营业执照', trigger: 'change'},
                backupContractDate: {
                    validator: (rule, value, callback) => {
                        if (!value || value.length !== 2)
                            return callback('请选择有效期')
                        return callback();
                    },
                    trigger: 'blur'
                }
                // officialSealPhoto: {message: '请选择电子公章', trigger: 'change'},
            },
            auditRuleForm: {
                refuseReason: [{required: true, message: '请填写拒绝通过的原因', trigger: 'blur'}],  // 拒绝原因
            },  // 审核form

        };

    },
    components: {
        SectionContent,
        comListSearch,
        paginationFoot,
        ImageUpload,
        // seePdf,
        CascaderCity,
        lookBigImages,
        dialogMiddleSection,
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
    },
    //定义事件方法
    methods: {
        handleSearch() {
            this.$refs.paginationFoot.changeCurrent(1)
        },
        handlePageInfo(pageInfo) {
            this.pageInfo = {
                ...this.pageInfo,
                ...pageInfo
            }
            this.searchClick();
        },
        resetClick() {
            // 重置
            this.$refs.cascaderCityForm.handleClear()
            this.form = {
                driverAuditStatus: '', // 司机信息
                driverCertificateAuditStatus: '',
                driverFlagStatus: '',
                onlineStatus: '',
                isCompleted: '',
                companyProvince: '',
                companyCity: '',
                signStatus: '',
                backupContractDate: []
            };
            this.supplierName = null;
            this.handleSearch()
        },
        async searchClick() {
            // let form = JSON.parse(JSON.stringify(this.form));
            this.loadingShow()
            let {
                    companyName,
                    businessContractName,
                    businessContractPhone,
                    businessContractMail,
                    onlineStatus,
                    isCompleted,
                    companyProvince,
                    companyCity,
                    signStatus,
                } = this.form,
                {
                    pageSize,
                    currentPage
                } = this.pageInfo,
                form = {
                    companyName,
                    businessContractName,
                    businessContractPhone,
                    businessContractMail,
                    onlineStatus,
                    isCompleted,
                    companyProvince,
                    companyCity,
                    signStatus,
                    pageIndex: currentPage,
                    pageSize
                };
            form = combinationForm(form)
            let res = await this.http("/galaxyZslTicketOrderApi/orgInfoApiPageList", form, "POST");
            if (res.success) {
                let listData = res.data.data;
                listData.forEach(item => {
                    item.OfflineShow = false;
                    item.Offline7Show = false;
                    item.goOnlineShow = false;
                    item.adminForm = {
                        userName: '',
                        userMobile: ''
                    };
                    item.operatorForm = [];
                    item.secondOrgList = [];
                })
                this.agencyList = listData;
                this.pageInfo.total = res.data.total;
            }
            this.loadingHide()
        },  // 获取中山陵机构信息列表
        createFilter(itemStr, index) {
            return (restaurant) => {
                let result = false;
                switch (index) {
                    case 1:
                        result = (restaurant.merchantName.indexOf(itemStr) >= 0);
                        break;
                }
                return result;
            };
        },
        async querySearch(query, cb, index) {
            let url = '';
            let form = {};
            let postType = 'GET';
            switch (index) {
                case 1:
                    url = '/galaxyMerchant/merchantInfo';
                    form = {
                        merchantName: query
                    }
                    break;
            }
            let res = await this.http(url, form, postType);
            if (res.success && res.data) {
                let mechanismList = query ? res.data.filter(this.createFilter(query, index)) : res.data;
                cb(mechanismList);
            } else {
                this.messageError('系统出错，请稍后!');
            }
        },
        async querySearchAsync(query, cb) {
            if (query && query.length > 1) {
                this.querySearch(query, cb, 1)
            }
        },  // 获取旅行社名称
        // 添加旅行社
        addTravelInfo(val) {
            this.operationBtnType = val;
            this.dialogTitle = '添加';
            this.handleSeeShow = true;
            this.determineShow = true;
        },

        async selectAgency(val) {
            this.form.companyName = val.merchantName;
            // this.form.createOrderOrganId = val.id;
        },


        // clickLookPicture(pictureUrl) {  // 查看图片
        //     this.lookPictureImg = !this.lookPictureImg;
        //     this.lookPictureImgUrl = pictureUrl;
        //     this.isFilePdf = this.handleIsFilePdf(pictureUrl)
        //
        //     // isPdf
        // },
        // handleIsFilePdf(fileUrl) {
        //     if (fileUrl) {
        //         let suffix = '';
        //         let result = '';
        //         try {
        //             let fileArr = fileUrl.split('.');
        //             suffix = fileArr[fileArr.length - 1];
        //         } catch (err) {
        //             suffix = '';
        //         }
        //         if (!suffix) {
        //             result = false;
        //             return result;
        //         }
        //         let pdfList = ['pdf'];
        //         let fileRes = pdfList.some(item => {
        //             return item == suffix
        //         })
        //         return fileRes
        //     }
        // },

        async updateZslOrgInfoConnect(item, index) {
            if (!item.adminForm.userName)
                return this.$message.error('请输入管理员姓名');
            if (!item.adminForm.userMobile)
                return this.$message.error('请输入手机号');
            if (item.adminForm.userMobile && !isCellphone(item.adminForm.userMobile))
                return this.$message.error('请输入正确的手机号');
            let form = {
                id: item.id,
                businessContractName: item.adminForm.userName,
                businessContractPhone: item.adminForm.userMobile,
            }
            let res = await this.http('/galaxyZslTicketOrderApi/updateZslOrgInfoConnect', form, 'POST');
            if (res.success && res.data) {
                this.$message({
                    message: '成功修改管理员信息',
                    type: 'success'
                });
                this.agencyList[index].isOrderChange = false;
                this.agencyList[index].showIndex = 0;
                item.adminForm = {
                    userName: '',
                    userMobile: ''
                };
                this.handleSearch();
                this.$forceUpdate();
            } else {
                this.$message.error('很抱歉，提交失败，请重新提交！');
            }
        }, // 管理员信息
//            async aaa() {
//                let form = {};
//                let res = await this.http('/galaxyZslTicketOrderApi/updateZslOrgInfoWorker', form, 'POST');
//            },
        showChange(index, type, item) {
            this.agencyList.forEach((item, idx) => {
                if (index != idx)
                    item.isOrderChange = false;
                else {
                    if (item.showIndex != type && type)
                        item.isOrderChange = true;//!this.orderList[index].isOrderChange;
                    else if (item.showIndex == type && !item.isOrderChange)
                        item.isOrderChange = true;
                    else item.isOrderChange = false;
                }
            })
            this.agencyList[index].showIndex = type ? type : 0;

            if (this.agencyList[index].isOrderChange) {
                switch (type) {
                    case 2:
                        this.handleListMerchant(index, item)
                        break;
                    case 3:
                        this.handleListTdsTripSecondOrg(index, item)
                        break;
                }
            }
            this.$forceUpdate();
        },
        async handleListMerchant(index, item) {
            let form = {
                id: item.id,
            }
            let res = await this.http('/galaxyZslTicketOrderApi/listFirstMerchantOperateById', form, 'POST')
            if (res.success && res.data) {
                let listData = res.data;
                listData.forEach(item => {
                    // item.mobile = item.loginNumber;
                    item.usefulStatus = item.usefulStatus || 0;
                })
                this.agencyList[index].operatorForm = listData;
            }
        },
        // TDS中山陵获取二级机构List
        async handleListTdsTripSecondOrg(index, item) {
            let form = {
                id: item.id,
            }
            const res = await this.http('/galaxyZslTicketOrderApi/listTdsTripSecondOrg', form, 'POST')
            if (res.success && res.data) {
                let list = res.data.map(item => ({
                    ...item.companyAddress = [item.companyProvince, item.companyCity],
                    ...item
                }))
                this.agencyList[index].secondOrgList = list;
            }
        },
        popoverShow(val, index) {
            let agencyList = JSON.parse(JSON.stringify(this.agencyList));
            agencyList.forEach((item, idx) => {
                item[val] = index == idx ? true : false;
            })
            this.agencyList = agencyList;
            this.$forceUpdate();
        },
        cancelClose() {
            let agencyList = JSON.parse(JSON.stringify(this.agencyList));
            agencyList.forEach(item => {
                item.OfflineShow = false;
                item.Offline7Show = false;
                item.goOnlineShow = false;
            })
            this.agencyList = agencyList;
            this.$forceUpdate();

        },
        addWayAddress(index) {
            this.agencyList[index].operatorForm.push({
                loginName: '',
                mobile: '',
                usefulStatus: 0,
                isEdit: true,
            });
        },
        handleCancel() {
            this.determineForm = {
                businessLincensePhoto: [],
                officialSealPhoto: [],
                travelBusinessLicensePhoto: [],
                powerAttorney: [],
                companyRegistered: [],
                companyProvince: '',
                companyCity: ''
            };
            this.determineShow = false
            this.$refs['determineForm'].resetFields();
        },
        addTravelAgency(form, type) {
            this.$refs[form].validate(async (valid) => {
                if (valid) {
                    let {
                            id,
                            companyName, // 旅行社名称
                            businessContractPhone, // 管理员手机号
                            businessContractName, // 管理员姓名
                            businessContractMail,
                            businessLincensePhoto,
                            officialSealPhoto,
                            travelBusinessLicensePhoto,
                            powerAttorney,
                            backupContractPhoto,
                            companyRegistered,
                            signStatus,
                            backupContractDate
                        } = this.determineForm,
                        form1 = {
                            id,
                            companyName, // 旅行社名称
                            businessContractPhone, // 管理员手机号
                            businessContractName, // 管理员姓名
                            businessContractMail,
                            signStatus,
                            businessLincensePhoto: businessLincensePhoto[0].imageUrl, // 营业执照
                            backupContractStartData: backupContractDate.length === 2 ? dayjs(backupContractDate[0]).valueOf() : undefined,
                            backupContractEndData: backupContractDate.length === 2 ? dayjs(backupContractDate[1]).valueOf() : undefined
                            // officialSealPhoto: this.determineForm.officialSealPhoto[0].imageUrl, //  电子公章
                            // travelBusinessLicensePhoto: this.determineForm.travelBusinessLicensePhoto[0].imageUrl, //  电子公章
                            // powerAttorney: this.determineForm.powerAttorney[0].imageUrl, //  电子公章
                        },
                        url = '',
                        message = '';
                    // errMessage = '';

                    if (companyRegistered && companyRegistered.length > 0) {
                        form1.companyProvince = companyRegistered[0];  // 省
                        form1.companyCity = companyRegistered[1];  // 市
                    }
                    if (officialSealPhoto && officialSealPhoto.length > 0 && officialSealPhoto[0].imageUrl) form1.officialSealPhoto = officialSealPhoto[0].imageUrl
                    if (travelBusinessLicensePhoto && travelBusinessLicensePhoto.length > 0 && travelBusinessLicensePhoto[0].imageUrl) form1.travelBusinessLicensePhoto = travelBusinessLicensePhoto[0].imageUrl
                    if (powerAttorney && powerAttorney.length > 0 && powerAttorney[0].imageUrl) form1.powerAttorney = powerAttorney[0].imageUrl
                    if (backupContractPhoto && backupContractPhoto.length > 0 && backupContractPhoto[0].imageUrl) form1.backupContractPhoto = backupContractPhoto[0].imageUrl
                    switch (type) {
                        case 1:
                            url = '/galaxyZslTicketOrderApi/addZslOrgInfo';
                            message = '旅行社信息提交成功';
                            // errMessage = '很抱歉，提交失败，请重新提交！';
                            break;
                        case 2:
                            url = '/galaxyZslTicketOrderApi/updateZslOrgInfo';
                            message = '修改旅行社信息成功';
                            // errMessage = '很抱歉，修改失败，请重新修改！';
                            break;
                    }
                    let res = await this.http(url, form1, 'POST');
                    if (res.success && res.data) {
                        this.$message({
                            message: message,
                            type: 'success'
                        });
                        this.determineShow = false;
                        this.handleCancel();
                        this.handleSearch();
                    } else {
                        // this.$message.error(errMessage);
                        this.$message.error(res.errors[0].message);
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },  // 添加旅行社信息

        imageUploadBusiness(img) {
            this.$set(this.determineForm, "businessLincensePhoto", img)
            if (img[0].imageUrl) this.seeBusiness = isFilePdf('businessLincensePhoto', img[0].imageUrl).isPdf;
        },  //营业执照
        imageUploadOfficial(img) {
            this.$set(this.determineForm, "officialSealPhoto", img)
            if (img[0].imageUrl) this.seeOfficial = isFilePdf('officialSealPhoto', img[0].imageUrl).isPdf;
        },  //电子公章
        imageUploadTravelBusiness(img) {
            this.$set(this.determineForm, "travelBusinessLicensePhoto", img)
            if (img[0].imageUrl) this.seeTravelBusiness = isFilePdf('travelBusinessLicensePhoto', img[0].imageUrl).isPdf;
        },  //旅行社经营许可证
        imageUploadPowerAttorney(img) {
            this.$set(this.determineForm, "powerAttorney", img)
            if (img[0].imageUrl) this.seePowerAttorney = isFilePdf('powerAttorney', img[0].imageUrl).isPdf;
        },  //授权书
        imageUploadBackupContractPhoto(img) {
            this.$set(this.determineForm, "backupContractPhoto", img)
            if (img[0].imageUrl) this.seeBackupContractPhoto = isFilePdf('backupContractPhoto', img[0].imageUrl).isPdf;
        },  // 合同照片
        handleTravelInfo(item, val) {
            this.operationBtnType = val;
            this.determineShow = true;
            switch (val) {
                case 2:
                    this.handleSeeShow = true;
                    this.dialogTitle = '修改';
                    break;
                case 3:
                    this.handleSeeShow = false;
                    this.dialogTitle = '查看';
                    break;
                case 4:
                    this.handleSeeShow = false;
                    this.dialogTitle = '审核';
                    break;
            }
            this.determineForm = {
                id: item.id,
                companyName: item.companyName, // 旅行社名称
                companyProvince: item.companyProvince, // 省
                companyCity: item.companyCity, // 市
                businessContractPhone: item.businessContractPhone, // 管理员手机号
                businessContractName: item.businessContractName, // 管理员姓名
                businessContractMail: item.businessContractMail, // 管理员姓名
                orgChargeInfoDto: item.orgChargeInfoDto, //
                signStatus: item.signStatus,  // 0:未签约;1:已签约
                backupContractDate: item.backupContractStartData ? [item.backupContractStartData, item.backupContractEndData] : []
                // officialSealPhoto: [{imageUrl: item.officialSealPhoto, showImage: true}], //  电子公章
                // travelBusinessLicensePhoto: [{imageUrl: item.travelBusinessLicensePhoto, showImage: true}], //  电子公章
                // powerAttorney: [{imageUrl: item.powerAttorney, showImage: true}], //  电子公章
            };
            if (item.companyProvince && item.companyCity) {
                this.determineForm.companyRegistered = [item.companyProvince, item.companyCity]
            }
            if (item.businessLincensePhoto) {
                this.determineForm.businessLincensePhoto = [{imageUrl: item.businessLincensePhoto, showImage: true}] // 营业执照
                this.seeBusiness = isFilePdf('businessLincensePhoto', item.businessLincensePhoto).isPdf;
            }
            if (item.officialSealPhoto) {
                this.determineForm.officialSealPhoto = [{imageUrl: item.officialSealPhoto, showImage: true}]
                this.seeOfficial = isFilePdf('officialSealPhoto', item.officialSealPhoto).isPdf;
            }
            if (item.travelBusinessLicensePhoto) {
                this.determineForm.travelBusinessLicensePhoto = [{
                    imageUrl: item.travelBusinessLicensePhoto,
                    showImage: true
                }]
                this.seeTravelBusiness = isFilePdf('travelBusinessLicensePhoto', item.travelBusinessLicensePhoto).isPdf;
            }
            if (item.powerAttorney) {
                this.determineForm.powerAttorney = [{imageUrl: item.powerAttorney, showImage: true}]
                this.seePowerAttorney = isFilePdf('powerAttorney', item.powerAttorney).isPdf;
            }
            if (item.backupContractPhoto) {
                this.determineForm.backupContractPhoto = [{imageUrl: item.backupContractPhoto, showImage: true}]
                this.seeBackupContractPhoto = isFilePdf('backupContractPhoto', item.backupContractPhoto).isPdf;
            }  // 合同照片
            // this.handleIsFilePdf(item.businessLincensePhoto)
            // this.addTravelInfo(val)
        },

        async updateOrgInfoOnline(item, val) {
            this.loadingShow();
            let form = {
                id: item.id,
                onlineStatus: val
            }, onlineMessageTitle = '';

            let res = await this.http('/galaxyZslTicketOrderApi/updateZslOrgInfoOnline', form, 'POST');

            if (res.success && res.data) {
                switch (val) {
                    case 0:
                        onlineMessageTitle = '下线';
                        item.OfflineShow = false;
                        break;
                    case 1:
                        onlineMessageTitle = '上线';
                        item.goOnlineShow = false;
                        break;
                    case 2:
                        onlineMessageTitle = '下线7天';
                        item.Offline7Show = false;
                        break;
                }
                this.$message.success(onlineMessageTitle + '成功');
                this.handleSearch();
                this.$forceUpdate();
            }
            this.loadingHide();
        },
        async handleStatusBtnClick(status, id, index, item) {
            this.loadingShow();
            // let api = '/galaxyRechargeProductApi/rechargeProductInfoStopUse/';//停用
            let title = '停用';
            if (status === 1) {
                // api = '/galaxyRechargeProductApi/rechargeProductInfoBeUse/'//启用
                title = '启用';
            }
            let form = {
                id,
                usefulStatus: status
            }
            const ret = await this.http(`/galaxyZslTicketOrderApi/updateZslOrgInfoWorkerUseful`, form, 'POST');
            this.loadingHide();
            if (ret.success && ret.data) {
                this.$message.success(`已${title}成功！`);
                this.handleListMerchant(index, item)
            } else
                this.$message.error(ret.errors[0].message)
        }, //启用停用
        async handleEditBtnClick(data, {$index}, index, item,) {
            let list = this.agencyList[index].operatorForm;
            //保存 更新
            let item1 = list[$index],
                url = '';
            const {
                loginName,
                mobile,
                loginuserManageId,
                id
            } = item1;
            //开启编辑模式
            let form = {
                companyName: this.agencyList[index].companyName,
                loginName,
                mobile,
            }
            if (!data.isEdit) {
                list[$index].isEdit = true;
                this.agencyList[index].operatorForm = [...list];
                return true;
            }
            if (!data.loginuserManageId) {
                url = '/galaxyZslTicketOrderApi/addZslOrgInfoWorker';
            } else {
                url = '/galaxyZslTicketOrderApi/updateZslOrgInfoWorker';
                form.loginuserManageId = loginuserManageId;
                form.loginuserId = id;
            }
            if (!loginName)
                return this.$message.error('请输入姓名');
            if (!mobile)
                return this.$message.error('请输入手机号');
            this.loadingShow()

            const res = await this.http(url, form, 'POST');
            if (res.success && res.data) {
                this.handleListMerchant(index, item);
                // this.$forceUpdate();
                this.loadingHide();
                this.$message.success('信息保存成功！！')
            } else {
                this.loadingHide();
            }
        },  // 修改保存操作员信息
        async handleDeleteBrnClick(id, index, item) {
            try {
                await this.$confirm('确定删除当前操作员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.loadingShow();
                let form = {
                    loginuserManageId: id
                }
                const res = await this.http('/galaxyZslTicketOrderApi/deleteZslOrgInfoWorker', form, 'POST')
                if (res.success && res.data) {
                    this.handleListMerchant(index, item)
                    this.$forceUpdate();
                    this.loadingHide();
                    this.$message.success('删除成功！');
                } else {
                    this.loadingHide();
                    this.$message.error(res.errors[0].message)
                }
            } catch (e) {
                console.log(e);
            }
        },  // 删除操作员
        // 审核通过拒绝操作
        handleAudit(type) {
            console.log(this.determineForm, type);
            this.auditShow = true
        },
        // 省市选择
        handleProvinceCity(data) {
            let {
                provinceCode,
                cityCode
            } = data
            this.form.companyProvince = provinceCode;
            this.form.companyCity = cityCode;
        },
        // auditForm 清除
        clearAuditForm(type) {
            this.auditShow = false;
            this.throughShow = false;
            this.determineShow = false;
            this.determineForm = {
                businessLincensePhoto: [],
                officialSealPhoto: [],
                travelBusinessLicensePhoto: [],
                powerAttorney: [],
                backupContractPhoto: [],
            };
            this.auditForm = {
                refuseReason: ''
            };
            if (type === 0) this.$refs['auditForm'].resetFields();
        },
        // 拒绝通过原因
        handleRefuseInfo() {
            this.$refs['auditForm'].validate(async (valid) => {
                if (valid) {
                    // this.loadingShow()
                    this.refuseCompletedLoad = true
                    let {
                            refuseInfo
                        } = this.auditForm,
                        {
                            id,
                            companyName,
                            businessContractMail
                        } = this.determineForm,
                        form = {
                            id,
                            companyName,
                            businessContractMail,
                            refuseInfo
                        };
                    console.log(form);
                    const res = await refuseCompleted(form)
                    if (res.success && res.data) {

                        this.refuseCompletedLoad = false
                        // this.$message({
                        //     message: '',
                        //     type: 'success'
                        // });
                        // this.handleCancel()
                        this.clearAuditForm(0)
                        this.handleSearch();
                        // this.loadingHide()
                        // this.handleCancel(form);
                    }
                } else {
                    console.log('error submit!!');
                    this.loadingHide()
                    return false;
                }
            });
        },
        // 审核通过
        async handlePassCompleted() {
            // this.loadingShow()
            this.passCompletedLoad = true
            let {
                    id,
                    companyName,
                    businessContractMail
                } = this.determineForm,
                form = {
                    id,
                    companyName,
                    businessContractMail
                };
            console.log(form);
            const res = await passCompleted(form)
            if (res.data && res.success) {
                this.passCompletedLoad = false
                this.clearAuditForm()
                this.handleSearch();
            }
        }
    },
    watch: {
        'determineForm.companyRegistered'(n) {
            if (n && n.length > 0) {
                this.determineForm.companyProvince = this.determineForm.companyRegistered[0];  // 省
                this.determineForm.companyCity = this.determineForm.companyRegistered[1];  // 市
            }
        },
    }
};
</script>

